import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`Problem #3`}</h5>
    <h6>{`Not every browser is created equal`}</h6>
    <p>{`On mobile it didn't work well with every browser, so I had to find a workaround for browsers where
the `}<strong parentName="p">{`AR functionality`}</strong>{` didn't work as expected.`}</p>
    <p>{`The solution of this problem was quite easy. Browsers where it didn't work well just don't use the
`}<strong parentName="p">{`AR functionality`}</strong>{`. Instead they show a fallback page where the user can select the image from the
magazine by its title.`}</p>
    <p>{`You can see a demonstration of this on the next page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      